import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, FormLabel,Toolbar,Switch,FormControlLabel,Select, FormControl} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
import ImageControl from './shares/react_image_control';

import classNames from 'classnames';
import Utils from '../api/api';
import {styles} from '../layout/theme';



// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';



// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }


class LecturerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specializes: [],
      statuses: ['InActive','Active'],
      lecturer: {
        avatar: '',
        fullnameVN:'',
        titleVN: '',
        summaryVN: '',
        fullnameEN:'',
        titleEN: '',
        summaryEN: '',
        // fullnameKR:'',
        // titleKR: '',
        // summaryKR: '',
        profileVN: '',
        profileVEN: '',
        //profileKR: '',
        urlVN: '',
        urlEN: '',
        webURL: '',
        facebookURL: '',
        linkedinURL: '',
        instagramURL: '',
        pinterestURL: '',
        twitterURL: '',
        isOnline: false,
        specialize:'',
        status: 0,
        dateCreated: new Date(),
        pinned: false,
        getflyId: ''
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        fullnameVN_error: false,
        fullnameEN_error: false,
        //fullnameKR_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      options: {'empty':'empty'},
      getflyLectureId: []
    };
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    Utils.getListData('specializes','all',100,'0,0',null,1,function(data){
      if (data.status === 'success') {
        $this.setState({specializes: data.results});
      }
    });

    Utils.getData('getfly/schedule/custom','',function(data) {
      //console.log(data);
      if(data.status==='success' && data.results.length) {
        let arr = data.results.filter(x=> x.field_name === 'gia_ng_vien');
        //console.log(arr);
        if(arr.length) {
          $this.setState({getflyLectureId: arr[0].options});
        }
      }
    });

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('lecturers',params.id, function(res) {
          if (res.status === 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                //console.log(item[property]);
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if(data.urlVN===undefined||data.urlVN===null){data.urlVN = '';}
            if(data.urlEN===undefined||data.urlEN===null){data.urlEN = '';}
            if(data.specialize===undefined||data.specialize===null){data.specialize = '';}
            if(data.dateCreated===undefined||data.dateCreated===null){data.dateCreated = new Date();}
            if(data.getflyId===undefined||data.getflyId===null){data.getflyId = '';}
            $this.setState({lecturer: data});
          }
        });
      });
    }

  }
  onEditorStateChange(field,value){
    var lecturer = this.state.lecturer;
    lecturer[field] = value;

    this.setState({lecturer:lecturer});
  }
  handleFieldChange(e) {
    var lecturer = this.state.lecturer;
    if(e.target.id!=='isOnline'&&e.target.id!=='pinned'){
      lecturer[e.target.id] = e.target.value;
    } else {
      if(lecturer[e.target.id]=='true'||lecturer[e.target.id]===true){
        lecturer[e.target.id] = 'false';
      } else {
        lecturer[e.target.id] = 'true';
      }
    }
    this.setState({lecturer:lecturer});
  }
  handleCover(value) {
    var lecturer = this.state.lecturer;
    lecturer.avatar = value;
    this.setState({lecturer:lecturer});
  }
  handleValidate(e,flag) {
    var lecturer = this.state.lecturer,
        obj = this.state.error;

    lecturer[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if(e.target.id==='fullnameVN'){
      var urlVN = 'giang-vien-' + Utils.getFriendlyURL(e.target.value);
      lecturer.urlVN = urlVN;
    }
    if(e.target.id==='fullnameEN'){
      var urlEN = 'lecturer-' + Utils.getFriendlyURL(e.target.value);
      lecturer.urlEN = urlEN;
    }

    this.setState({lecturer: lecturer, error:obj}, () => {
    });
  }
  _save(){
    var $this = this;
    var data = this.state.lecturer;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('lecturer',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/lecturers/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('lecturer',data, function(res) {
        if (res.status === 'success') {
          $this.context.router.history.push('/lecturers/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['fullnameVN_error'] = this.state.lecturer.fullnameVN === '';
          obj['vn'] = obj['fullnameVN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['fullnameEN_error'] = this.state.lecturer.fullnameEN === '';
          obj['en'] = obj['fullnameEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['fullnameKR_error'] = this.state.lecturer.fullnameKR === '';
        //   obj['kr'] = obj['fullnameKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/lecturers/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const lecturer = this.state.lecturer;
    const editorConfig = Utils.setupEditor('others',this.state.options);


    //console.log(lecturer.isOnline);

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa giảng viên</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="fullnameVN" label={language.VN.name} className={classes.textField} value={lecturer.fullnameVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.fullnameVN_error} margin="normal"/>
            <FormGroup className={classes.formRowInline}>
              <FormControl className={classes.col6}>
              {
                this.state.getflyLectureId!==undefined ? (
                  <React.Fragment>
                    <Typography className={classes.label}>Đồng bộ với GetFly System:</Typography>
                    <Select native id='getflyId' value={lecturer.getflyId} onChange={event => this.handleFieldChange(event)}>
                      <option value="" key={0}>...</option>
                      {this.state.getflyLectureId.map((item, index) => {
                        return (
                          <option key={index + 1} value={item.list_option_id}>
                            {item.list_value}
                          </option>
                        );
                      })}
                    </Select>
                  </React.Fragment>
                ):''
              }
              </FormControl>
              <FormControl className={classes.col6}>
                <TextField id="titleVN" label='Công việc/Chức vụ' className={'form-control-no-bottom'} value={lecturer.titleVN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </FormControl>
            </FormGroup>
            <TextField id="urlVN" label="Link [SEO]" className={classes.textField} value={lecturer.urlVN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormGroup className={classes.formRowInline}>
              <FormControl className={classes.col6}>
                <Typography className={classes.label}>Chuyên môn</Typography>
                <Select
                  native
                  className={classes.selectMultiLevel}
                  id="specialize"
                  value={lecturer.specialize}
                  onChange={event => this.handleFieldChange(event)}
                >
                  <option value="" key={0}>
                    ...
                  </option>
                  {this.state.specializes.map((item, index) => {
                    return (
                      <option key={index + 1} value={item._id}>
                        {item.nameVN}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.col6}>
                <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    className={classes.selectMultiLevel}
                    id="status"
                    value={lecturer.status}
                    onChange={event => this.handleFieldChange(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
              </FormControl>
            </FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="isOnline"
                  checked={lecturer.isOnline==='true'}
                  onChange={(event)=>this.handleFieldChange(event)}
                />
              }
              label="Giảng viên online"
            />
            <FormControlLabel
              control={
                <Switch
                  id="pinned"
                  checked={lecturer.pinned==='true'}
                  onChange={(event)=>this.handleFieldChange(event)}
                />
              }
              label="Ghim"
            />
            <ImageControl style={{paddingTop:0}} label="Hình đại diện" value={lecturer.avatar} handleChange={(value) => this.handleCover(value)}></ImageControl>
            <FormGroup className={classes.formRowInlineTop}>
              <div className={classes.col6}>
                <TextField id="facebookURL" type="url" label="Link Facebook" className={classes.textField} value={lecturer.facebookURL} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </div>
              <div className={classes.col6}>
                <TextField id="instagramURL" type="url" label="Link Instagram" className={classes.textField} value={lecturer.instagramURL} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </div>
            </FormGroup>
            <FormGroup className={classes.formRowInlineTop}>
              <div className={classes.col6}>
                <TextField id="linkedinURL" type="url" label="Link LinkedIn" className={classes.textField} value={lecturer.linkedinURL} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </div>
              <div className={classes.col6}>
                <TextField id="twitterURL" type="url" label="Link twitter" className={classes.textField} value={lecturer.twitterURL} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </div>
            </FormGroup>
            <FormGroup className={classes.formRowInlineTop}>
              <div className={classes.col6}>
                <TextField id="pinterestURL" type="url" label="Link Pinterest" className={classes.textField} value={lecturer.pinterestURL} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </div>
              <div className={classes.col6}>
                <TextField id="webURL" type="url" label="Link web" className={classes.textField} value={lecturer.webURL} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
              </div>
            </FormGroup>
            <FormLabel className={classes.label_shrink}>{language.VN.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.lecturer.summaryVN}
              onModelChange={(value)=>this.onEditorStateChange('summaryVN',value)}
            />
            <FormLabel className={classes.label_shrink}>{language.VN.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.lecturer.profileVN}
              onModelChange={(value)=>this.onEditorStateChange('profileVN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="fullnameEN" label={language.EN.name} className={classes.textField} value={lecturer.fullnameEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.fullnameEN_error} margin="normal"/>
            <TextField id="titleEN" label={language.EN.title} className={classes.textField} value={lecturer.titleEN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <TextField id="urlEN" label="Link [SEO]" className={classes.textField} value={lecturer.urlEN} onChange={(event) => this.handleFieldChange(event)} margin="normal"/>
            <FormLabel className={classes.label_shrink}>{language.EN.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={lecturer.summaryEN}
              onModelChange={(value)=>this.onEditorStateChange('summaryEN',value)}
            />
            <FormLabel className={classes.label_shrink}>{language.EN.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.lecturer.profileEN}
              onModelChange={(value)=>this.onEditorStateChange('profileEN',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="fullnameKR" label={language.KR.name} className={classes.textField} value={lecturer.fullnameKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.fullnameKR_error} margin="normal"/>
            <TextField id="titleKR" label={language.KR.title} className={classes.textField} value={lecturer.titleKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.titleKR_error} margin="normal"/>
            <FormLabel className={classes.label_shrink}>{language.KR.summary}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={lecturer.summaryKR}
              onModelChange={(value)=>this.onEditorStateChange('summaryKR',value)}
            />
            <FormLabel className={classes.label_shrink}>{language.KR.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={this.state.lecturer.detailKR}
              onModelChange={(value)=>this.onEditorStateChange('detailKR',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

LecturerDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
LecturerDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(LecturerDetail);
