import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField, Button,Select,FormControl,SnackbarContent} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import WarningIcon from '@material-ui/icons/Warning';

import classNames from 'classnames';
import ReactTable from './shares/react_table';
//import ReactLazyCardMedia from './shares/react_lazy_card';
import language from '../layout/language';

import {styles} from '../layout/theme';
import Utils from '../api/api';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export class LecturerList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      openModal: false,
      filter: '',
      sorts: '',
      specializes: [],
      statuses: ['InActive','Active'],
      status: '',
      specialize:'',
      dateStart: '',
      dateEnd: '',
      objSpecialize: {
        nameVN:'',
        nameEN:''
      },
      selected: [],
      message: ''
    }
  }
  componentDidMount() {
    const $this = this;
    this.setState({
      sorts: JSON.stringify({pinned:-1,dateCreated:1})
    });
    Utils.getListData('specializes','all',100,'0,0',null,1,function(data){
      if (data.status === 'success') {
        $this.setState({specializes: data.results});
      }
    });
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  filterData(e){
    const $this = this,
          state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state,function(){
      $this.handleFilter();
    });
  }
  handleFilter() {
    let f = this.state.filter;

    if(f==='') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if(this.state.specialize!==''){
      f.specialize = this.state.specialize;
    } else {
      if(f.specialize!==undefined) {
        delete f.specialize;
      }
    }

    if(this.state.status!==''){
      f.status = parseInt(this.state.status);
    } else {
      if(f.status!==undefined) {
        delete f.status;
      }
    }

    if(this.state.dateStart!==''){
      f.dateCreated = {$gte:new Date(this.state.dateStart + ' 23:59:59')};
    } else {
      if(f.dateCreated!==undefined && this.state.dateEnd==='') {
        delete f.dateCreated;
      }
    }

    if(this.state.dateEnd!==''){
      f.dateCreated = {$lte:new Date(this.state.dateEnd + ' 23:59:59')};
    } else {
      if(f.dateCreated!==undefined && this.state.dateStart==='') {
        delete f.dateCreated;
      }
    }

    this.setState({filter:JSON.stringify(f)});
  }
  handleAdd(){
    this.context.router.history.push('/lecturers/add');
  }
  handleCloseModal(){
    this.setState({openModal:false})
  }
  openSpecializes() {
    this.setState({openModal:true})
  }
  handleFieldSpecialize(e) {
    var obj = this.state.objSpecialize;
    obj[e.target.id] = e.target.value;
    this.setState({objSpecialize:obj})
  }
  handleAddSpecialize(){
    const $this = this;
    Utils._add('specialize',this.state.objSpecialize,function(data){
      if(data.status==='success') {
        let arr = $this.state.specializes;
        arr.push(data.data);
        $this.setState({specializes:arr},function(){
          $this.handleCloseModal();
        });
      }
    });
  }
  onChangeSelect(data){
    this.setState({selected:data});
  }
  handleActive(data) {
    const $this = this;
    var arr = [];
    data.map(x=>{
      let o = {_id:x._id,status:1};
      arr.push(o);
    });
    Utils._updateMany('lecturers',arr,function(data){
      if(data.status==='success') {
        $this.handleFilter();
      }
    });
  }
  handleInActive(data) {
    const $this = this;

    var lecs = [], no = [];


    this.setState({message:''},function(){
      var promises = data.map((x,i)=>{
        return new Promise(function(resolve,reject){
          Utils.getData('listing/courses/1/all/0,0/'+JSON.stringify({lecturers:x._id})+'/'+JSON.stringify({pinned:-1,dateCreated:1})+'/1','',function(res){
            //console.log(res);
            if(res.status==='success') {
              if(res.results.length) {
                let items = res.results;
                for(let z=0,len=items.length;z<len;z++){
                  var temp = [];
                  if(items[z].lecturers.length<2) {
                    if(no.indexOf(x.fullnameVN)===-1) {
                      no.push(x.fullnameVN);
                    }
                    if(temp.length) {
                      temp = [];
                    }
                  } else {
                    temp.push({_id:x._id,status:0});
                  }
                  if(z===items.length-1) {
                    if(temp.length) {
                      lecs = lecs.concat(temp);
                    }
                  }
                }

              } else {
                lecs.push({_id:x._id,status:0});
              }
              resolve([lecs,no]);
            } else {
              reject([lecs,no]);
            }
          });
        }).catch((err)=>{
          console.log(err);
          return err;
        });
      });

      Promise.all(promises).then((d) => {
        // console.log(d);
        if(no.length)$this.setState({message:'Giảng viên ' + no.join(',') + ' là giảng viên duy nhất của lớp.'})

        if(lecs.length) {
          Utils._updateMany('lecturers',lecs,function(data){
            if(data.status==='success') {
              $this.handleFilter();
            }
          });
        }
      }).catch((error)=>{
        console.log(error);
      });


    });

  }
  handlePin(data){
    const $this = this;
    var arr = [];
    data.map(x=>{
      let o = {_id:x._id,pinned:true};
      arr.push(o);
    });
    Utils._updateMany('lecturers',arr,function(data){
      if(data.status==='success') {
        $this.handleFilter();
      }
    });
  }
  handleUnPin(data){
    const $this = this;
    var arr = [];
    data.map(x=>{
      let o = {_id:x._id,pinned:false};
      arr.push(o);
    });
    Utils._updateMany('lecturers',arr,function(data){
      if(data.status==='success') {
        $this.handleFilter();
      }
    });
  }
  render(){
    const $this = this,
          classes = $this.props.classes;

    const Bulk = [{
      title: 'Kích hoạt (Active)',
      action: (data)=>$this.handleActive(data)},{
      title: 'Hủy kích hoạt (InActive)',
      action: (data)=>$this.handleInActive(data)},{
      title: 'Ghim',
      action: (data)=>$this.handlePin(data)},{
      title: 'Hủy ghim',
      action: (data)=>$this.handleUnPin(data)}];

    var source = {
      thead: [{ title: 'Tên giảng viên', value: 'name'}, { title: 'Chuyên môn', value: 'specialize'}, { title: 'Ngày tạo', value: 'date'},{
        title:'Ghim',value:'pin'},{title:'Trạng thái',value:'status'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.fullnameVN,
          specialize: $this.state.specializes.length&&$this.state.specializes.map(x=>{
            return x._id==n.specialize?x.nameVN:'';
          }),
          date: <div>{Utils.formatFullDate(n.dateCreated)}</div>,
          pin: n.pinned==='true'?<span className="fa fa-check"></span>:'',
          status: n.status!=1?'InActive':'Active'
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục giảng viên</Typography>
          <Button variant="contained" color="secondary" onClick={()=>this.openSpecializes()}>Thêm chuyên môn</Button>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col3}>
                  <Typography className={classes.label}>Chuyên môn</Typography>
                  <Select
                    native
                    id="specialize"
                    value={$this.state.specialize}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.specializes.map((item, index) => {
                      return (
                        <option key={index + 1} value={item._id}>
                          {item.nameVN}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    id="status"
                    value={$this.state.status}
                    onChange={event => this.filterData(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="date" id="dateStart" label="Từ" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateStart)} onChange={event => this.filterData(event)}
                  margin="normal"/>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="date" id="dateEnd" label="Đến" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateEnd)} onChange={event => this.filterData(event)}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col1}>
                  <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiBulk={Bulk} onChangeSelect={(items)=>$this.onChangeSelect(items)} showCheckBox={true} apiDuplicate={true} apiList='lecturers' apiSingle='lecturer' apiTable='lecturers' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} apiSort={this.state.sorts} apiFilter={this.state.filter}>
          </ReactTable>
        </Paper>
        <Dialog
          open={this.state.openModal}
          onClose={()=>this.handleCloseModal()}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogForm}>
            <FormGroup>
              <FormControl>
                <TextField id="nameVN" label="Chuyên môn" className={classNames(classes.textField)} required={true} margin="normal" onChange={(e)=>this.handleFieldSpecialize(e)}/>
              </FormControl>
              <FormControl>
                <TextField id="nameEN" label="Chuyên môn (En)" className={classNames(classes.textField)} required={true} margin="normal" onChange={(e)=>this.handleFieldSpecialize(e)}/>
              </FormControl>
            </FormGroup>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={()=>this.handleCloseModal()}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={()=>this.handleAddSpecialize()}>{language.VN.btnSave}</Button>
            </FormGroup>
          </div>
        </Dialog>
        <Dialog
          open={this.state.message!==''}
          TransitionComponent={Transition}
          onClose={()=>{
            this.setState({message:''})
          }}
        >
          <SnackbarContent
            className={classes.messageRow}
            aria-describedby="client-snackbar"
            message={
              <div id="client-snackbar" className={classes.formRowInline}>
                <WarningIcon/>
                <Typography style={{color: '#fff'}}>{this.state.message}</Typography>
              </div>
            }
          />
        </Dialog>
      </div>
    )
  }
}

LecturerList.propTypes = {
  classes: PropTypes.object.isRequired,
};
LecturerList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(LecturerList);